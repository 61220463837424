import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout";

const Page = ({
  data: {
    pagesJson: {
      author,
      description,
      image,
      metaTags,
      slug,
      title,
      components,
    },
  },
  location,
}) => (
  <Layout
    location={location}
    title={title}
    description={description}
    image={image}
    author={author}
    metaTags={metaTags}
    slug={slug}
    components={components}
  >
    <h1 style={{ display: "none" }}>
      {title +
        `${title === "Gen4 Dental Partners" ? "" : " | Gen4 Dental Partners"}`}
    </h1>
  </Layout>
);

export const query = graphql`
  query ($id: String) {
    pagesJson(id: { eq: $id }) {
      author
      description
      image
      metaTags
      slug
      title
      components {
        id
        template
      }
      ...Accordion
      ...AlternatingList
      ...Banner
      ...Columns
      ...ColumnsWithSeparators
      ...Comparison
      ...Contact
      ...ContactDrReferrals
      ...ContactCareers
      ...Cta
      ...Goals
      ...Gen4Agenda
      ...Hero
      ...HeroWithCountdown
      ...HeroWithLogoAwardsArchive
      ...HeroWithLogo
      ...HeroWithLogo24
      ...ImageWithHeadingAndText
      ...ImagesAndCaption
      ...LogoCarousel
      ...Overview
      ...People
      ...Tabs
      ...TabsWithRichText
      ...TextAndButton
      ...TextAndImage
      ...VideoHero
    }
  }
`;

export default Page;
